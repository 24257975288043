import React from 'react';
import {isMobile} from "react-device-detect";
import {isBrowser} from '../../utils/articleUtil'

class FbComments extends React.Component {
    componentDidMount() {
        setTimeout(() => {
            const fbCommentContainer = document.getElementById("fb-root");
            if (fbCommentContainer && !document.querySelector('script[src*="connect.facebook.net/en_GB/sdk.js"]')) {
                const fbScript = document.createElement("script");
                fbScript.async = true;
                fbScript.defer = true;
                fbScript.crossOrigin = "anonymous";
                fbScript.src = `https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v12.0&appId=1068782156864355&autoLogAppEvents=1`;
                fbScript.type = "text/javascript";
                fbScript.setAttribute('nonce', "arWiyzxW");
                fbCommentContainer.after(fbScript);
            } else {
              isBrowser() && window.FB.XFBML.parse();
            }
        }, 1000 * (isMobile ? 6 : 1));
    }

    render() {
        return <>
            <div id="fb-root"/>
            <div className="fb-comments"
                 data-href={this.props.url} data-width="100%"
                 data-numposts="5"/>
        </>;
    }
}

export default FbComments;
